<template>
  <div>
    <b-overlay
      :show="overlay"
      opacity="0.17"
      blur="1rem"
      rounded="md"
      variant="secondary"
    >
      <b-sidebar
        id="sidebar-backdrop"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        backdrop
        right
        no-header
        shadow
      >
        <NonImportedPeriodicView
          :openSidebar="openSidebar"
          :closeSidebar="closeSidebar"
          :showOverlay="showOverlay"
          :hideOverlay="hideOverlay"
          :reloadParent="load"
        />
      </b-sidebar>
      <div class="card">
        <div class="card-header">
          <h4 class="font-weight-bold mb-1">My Periodic Activities</h4>
          <b-input-group>
              <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
              class="w-75"
              v-model="search"
              type="search"
              placeholder="Search Periodic Activities..."
              ></b-form-input>
          </b-input-group>
        </div>
        <table class="table table-condensed">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" class="text-center">Standard</th>
              <th scope="col">Owner</th>
              <th scope="col">Frequency</th>
              <th scope="col">Start Date</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="periodicactivities && periodicactivities.length > 0"
            >
              <tr
                v-for="(periodic, index) in this.periodicactivities"
                :key="periodic._id"
              >
                <td style="max-width: 16vw">
                  <p class="mb-0">{{ periodic.title }}</p>
                  <small class="mt-75">{{ periodic.description }}</small>
                </td>

                <td style="max-width: 12vw">
                  <div
                    class="w-100 d-flex align-items-center justify-content-center flex-wrap"
                  >
                    <b-badge
                      :key="std.name"
                      v-for="std in periodic.standards"
                      pill
                      variant="light-primary"
                      class="mx-25 mb-25"
                    >
                      {{ std.name }}
                    </b-badge>
                  </div>
                </td>

                <td>
                  <select
                    name=""
                    id=""
                    class="form-control w-100"
                    v-model="periodic.activity_owner"
                  >
                    <option
                      v-for="user in userlist"
                      :key="user.user_id"
                      :value="user.user_id"
                    >
                      {{ user.firstname }} {{ user.lastname }}
                    </option>
                  </select>
                  <!-- {{periodic.activity_owner}} -->
                </td>
                <td>
                  <select
                    name=""
                    :id="periodic._id + `${index}`"
                    class="form-control w-100"
                    v-model="periodic.frequency_unit"
                  >
                    <option value="3">Weekly</option>
                    <option value="4">Bi-weekly</option>
                    <option value="5">Monthly</option>
                    <option value="6">Quarterly</option>
                    <option value="7">Half Yearly</option>
                    <option value="8">Yearly</option>
                  </select>
                </td>
                <td>
                  <flat-pickr
                    v-model="periodic.start_date"
                    class="form-control w-100"
                    :id="periodic._id + `${index}`"
                    :config="{
                      altInput: true,
                      altFormat: 'd-M-Y',
                      dateFormat: 'Y-m-d'
                    }"
                  />
                </td>
                <td>
                  <select
                    name=""
                    class="form-control w-100"
                    v-model="periodic.status"
                    :id="periodic._id + `${index}`"
                  >
                    <option value="1">Enable</option>
                    <option value="2">Disable</option>
                  </select>
                </td>
                <td>
                  <!-- :disabled="!periodic.activity_owner!=null && !periodic.frequency_unit!=null && !periodic.start_date !=null" -->
                  <b-button
                    @click="
                      UpdatePeriodic(
                        periodic._id,
                        periodic.frequency_unit,
                        periodic.activity_owner,
                        periodic.start_date,
                        periodic.status
                      )
                    "
                    variant="primary"
                    size="sm"
                    >Update</b-button
                  >
                </td>
              </tr>
            </template>
            <template v-else>
                    <empty-table-section title="Periodic Activities Not Found">
                      <template #content>
                        <p class="font-weight-bold text-center">
                          It seems like there are no Periodic Activities at the moment.
                        </p>
                      </template>
                    </empty-table-section>
              </template>
            </tbody>
          </table>
          <div class="card-body">
        <div class="row">
          <div class="d-flex align-items-center justify-content-center col-12">
            <b-pagination
              v-model="currentPage"
              :per-page="pagination.per-page"
              :total-rows="pagination.rows"
              aria-controls="my-table"
              class="mb-0"
            ></b-pagination>
          </div>
        </div>
      </div>
        </div>
      </b-card>
    </b-overlay>
    <b-modal
      ref="modal-periodic"
      title="Edit Periodic Activity "
      hide-footer
      size="lg"
    >
      <label for="">Frequency</label>

      <label for="">Repeat</label>
      <!-- <input type="text" class="form-control mb-1" v-model="repeat_value" /> -->
      <label for="">Activity Owner</label>

      <b-form-group label="Status" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="selected"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="plain-inline"
          plain
        ></b-form-radio-group>
      </b-form-group>
      <button class="btn btn-success btn-sm" @click="UpdatePeriodic()">
        Save & Publish
      </button>

      <!-- {{label_options}} -->
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BButton,
  VBToggle,
  BSidebar,
  BOverlay,
  BModal,
  BFormRadio,
  BFormGroup,
  BFormRadioGroup,
  BBadge,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput
} from "bootstrap-vue";
import StandardMixins from "../../mixins/StandardMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import NonImportedPeriodicView from "./components/NonImportedPeriodicView.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  name: "Standards",
  mixins: [StandardMixins, ResponseMixins],
  components: {
    BCard,
    BCardBody,
    BTable,
    BButton,
    BSidebar,
    BOverlay,
    NonImportedPeriodicView,
    BModal,
    BFormRadio,
    BFormGroup,
    BFormRadioGroup,
    flatPickr,
    vSelect,
    BBadge,
    EmptyTableSection,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput
  },
  directives: { "b-toggle": VBToggle },
  data() {
    return {
      fields: [
        { key: "title", label: "Name", tdClass: "font-weight-bold" },
        { key: "description", label: "Description", tdClass: "font-italic" },
        { key: "owner", label: "Owner", tdClass: "owner" },
        { key: "frequency", label: "Frequency", tdClass: "font-weight-bold" },
        { key: "startdate", label: "Start Date", tdClass: "font-weight-bold" },
        { key: "status", label: "Status", tdClass: "font-weight-bold" },
        // { key: "region", label: "Region", tdClass: "font-weight-bold" },
      ],
      items: [],

      overlay: false,
      periodicactivities: [],
      selected: "",
      search:null,
      options: [
        { text: "Disable", value: "2" },
        { text: "Enable", value: "1" },
      ],
      user_options: [],
      userlist: [],
      periodic: {},
      owner: [],
      frequency: [],
      startDate: [],
      status: [],
      validation_isms: [],
      currentPage:1,
      pagination:{}
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      //   this.getStandardsAndSetData();
      this.getPeriodicActivities();
      this.getUserlist();
    },
    UpdatePeriodic(paid, frequency, owner, startdate, status) {
      const bodyFormData = new FormData();
      bodyFormData.append("frequency_unit", frequency);
      bodyFormData.append("activity_owner", owner);
      bodyFormData.append("status", status);
      bodyFormData.append("start_date", startdate);
      const options = {
        method: "POST",
        data: bodyFormData,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/periodic-activities/${paid}`,
      };
      this.$http(options)
        .then((resp) => {
          //   this.periodicactivities = resp.data.data.data;
          this.getPeriodicActivities();
          if (resp.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: resp.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: resp.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err + "jfndj");
          this.validation_isms = err;
        });
    },
    getPeriodicActivities() {
      this.showOverlay();
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/periodic-activities`,
        params:{
          page:this.currentPage,
          search:this.search
        }
      };
      this.$http(options)
        .then((resp) => {
          this.periodicactivities = resp.data.data.data;
          console.log('periodic',resp.data.data)
          this.pagination={
            'per-page':resp.data.data.per_page,
            rows:resp.data.data.total
          }
          
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.hideOverlay();
        });
    },
    getUserlist() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/users`,
      };
      this.$http(options)
        .then((resp) => {
          this.userlist = resp.data.data.data;
          this.user_options = [];
          // this.selectedPeriod = [];
          for (let [key, value] of Object.entries(this.userlist)) {
            let a = {
              value: value.user_id,
              title: value.firstname + "" + value.lastname,
            };
            this.user_options.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    getImportedStandard(standard) {
      this.items.push(standard);
    },

    showOverlay() {
      this.overlay = true;
    },
    hideOverlay() {
      this.overlay = false;
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    editperiodic() {
      this.$refs["modal-periodic"].show();
    },
  },
  watch:{
    currentPage(){
      this.getPeriodicActivities()
    },
    search(){
      this.getPeriodicActivities()
    }
  }
};
</script>

<style></style>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 40rem !important;
}
.owner {
  width: 260px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
