<template>
  <tr style="height: 130px" class="bg-transparent">
    <td colspan="12">
      <div
        class="w-auto d-flex flex-column align-items-center justify-content-center"
        style="padding: 3rem 0"
      >
        <b-badge
          variant="light-primary"
          pill
          style="width: 3.6rem; height: 3.6rem"
          class="mb-1 d-flex align-items-center justify-content-center position-relative"
        >
          <feather-icon icon="DatabaseIcon" style="width: 24px; height: 30px" />
        </b-badge>
        <h4 class="font-weight-bolder">{{ title }}</h4>
        <slot name="content"> </slot>
      </div>
    </td>
  </tr>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {BBadge} from 'bootstrap-vue'
export default {
  components: { FeatherIcon, BBadge },
  props: {
    title: {
      type: String,
      required: false,
      default: "Empty Content",
    },
  },
};
</script>

<style></style>
